import axiosClient from "./axiosClient";

const statisticalApi = {
  async getStatistical(data) {
    const url = `/hirc/statistical`;
    return axiosClient.post(url, data);
  },
};

export default statisticalApi;
