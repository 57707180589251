import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import statisticalApi from "../../api/statisticalApi";

export const getStatistical = createAsyncThunk(
  "statistical/getStatistical",
  async (data) => {
    const listStatistical = await statisticalApi.getStatistical(data);
    return listStatistical;
  }
);

const statisticalSlice = createSlice({
  name: "statistical",
  initialState: {
    loadingStatistical: false,
    statisticalList: [],
  },
  reducers: {},
  extraReducers: {
    [getStatistical.pending]: (state, action) => {
      state.loadingStatistical = true;
    },
    [getStatistical.rejected]: (state, action) => {
      state.loadingStatistical = false;
    },
    [getStatistical.fulfilled]: (state, action) => {
      state.loadingStatistical = false;
      state.statisticalList = action.payload;
    },
  },
});

export default statisticalSlice;
