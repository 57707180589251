import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getStatistical } from "../../redux/reducers/statisticalSlice";

const Statistical = () => {
  const [show, setShow] = useState(false);
  const { register, reset, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const handleCheck = (data) => {
    dispatch(
      getStatistical({
        UserName: data?.username,
        PassWord: data?.password,
      })
    );
    setShow(true);
  };
  const { statisticalList } = useSelector((state) => state.statistical);

  return (
    <div>
      <title>Test API</title>
      <div className="container" style={{ textAlign: "start" }}>
        <div className="jumbotron">
          <h3>KIỂM TRA API TÍCH HỢP ONLINE.GOV.VN</h3>
          <h3>
            <div>
              <form>
                <div className="form-group">
                  <label htmlFor="email">Tài khoản:</label>
                  <input
                    type="email"
                    {...register("username")}
                    className="form-control"
                    id="username"
                    name="username"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="pwd">Mật khẩu:</label>
                  <input
                    type="password"
                    {...register("password")}
                    className="form-control"
                    id="password"
                    name="password"
                  />
                </div>
                <button
                  onClick={handleSubmit(handleCheck)}
                  type="submit"
                  className="btn btn-primary btn-lg"
                >
                  Kiểm tra
                </button>
              </form>
              {show ? (
                <>
                  <table
                    style={{ marginTop: "20px" }}
                    className="table table-bordered table-info table100"
                  >
                    <tbody>
                      <tr>
                        <td className="labelData labelDataTitle" colSpan={2}>
                          <strong>Số liệu thống kê</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <strong>Số lượng truy cập</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Tính từ 1/1/ đến thời điểm thống kê</td>
                        <td>
                          <p className="txt-number">
                            {statisticalList?.soLuongTruyCap}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <strong>Số người bán</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Tổng số trên hệ thống
                            <br />
                            <i> (Tính đến Thời điểm thống kê)</i>
                          </span>
                        </td>
                        <td>
                          <p className="txt-number">
                            {statisticalList?.soNguoiBan}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Số lượng người bán mới <br />
                            <i>(Tính từ 1/1/ đến Thời điểm thống kê)</i>
                          </span>
                        </td>
                        <td>
                          <p className="txt-number">
                            {statisticalList?.soNguoiBanMoi}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <strong>Số lượng sản phẩm</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Tổng số sản phẩm (SKU)
                            <br />
                            <i> (Tính đến Thời điểm thống kê)</i>
                          </span>
                        </td>
                        <td>
                          <p className="txt-number">
                            {statisticalList?.tongSoSanPham}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Số sản phẩm đăng bán bán mới <br />
                            <i>(Tính từ 1/1/ đến Thời điểm thống kê)</i>
                          </span>
                        </td>
                        <td>
                          <p className="txt-number">
                            {statisticalList?.soSanPhamMoi}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <strong>Số lượng giao dịch</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Tổng số số đơn hàng
                            <br />
                            <i>(Tính từ 1/1/ đến Thời điểm thống kê)</i>
                          </span>
                        </td>
                        <td>
                          <p className="txt-number">
                            {statisticalList?.soLuongGiaoDich}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Tổng số đơn hàng thành công
                            <br />
                            <i> (Tính từ 1/1/ đến Thời điểm thống kê)</i>
                          </span>
                        </td>
                        <td>
                          <p className="txt-number">
                            {statisticalList?.tongSoDonHangThanhCong}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Tổng số đơn hàng không thành công
                            <br />
                            <i> (Tính từ 1/1/ đến Thời điểm thống kê)</i>
                          </span>
                        </td>
                        <td>
                          <p className="txt-number">
                            {statisticalList?.tongSoDonHangKhongThanhCong}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <strong>Tổng giá trị giao dịch</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Tổng số này ứng với tổng số đơn hàng giao dịch thành
                            công
                            <br />
                            <i> (Tính từ 1/1/ đến Thời điểm thống kê)</i>
                          </span>
                        </td>
                        <td>
                          <p className="txt-number">
                            {statisticalList?.tongGiaTriGiaoDich}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : (
                ""
              )}
            </div>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Statistical;
