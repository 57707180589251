import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import NoMatch from "./components/404/NoMatch";
import ProtectedRoutes from "./helpers/ProtectedRoutes";
import React, { Suspense, useEffect } from "react";
import nprogress from "nprogress";
import { getMyInfo } from "./redux/reducers/user";
import { getCourse } from "./redux/reducers/course";
import Loading from "./components/Loading/Loading";
import ConfirmRegister from "./view/Auth/Register/ConfirmRegister";
import ForgotPassword from "./components/Auth/Login/ForgotPassword/ForgotPassword";
import PaymentOnepay from "./components/Order/PaymentOnepay";
import Detail from "./view/training/Detail";
import DomesticFairs from "./view/expo/DomesticFairs";
import DomesticFairsDetail from "./view/expo/DomesticFairsDetail";
import RegisterStall from "./view/expo/RegisterStall";
import RegisterVisit from "./view/expo/RegisterVisit";
import Cooperate from "./view/cooperate/Cooperate";
import RegisterCourse from "./view/training/register/RegisterCourse";
import ScrollTop from "./utils/ScrollTop";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import Routers from "./Routers";
import Aos from "aos";
import IframeShop from "./components/IframeShop/IframeShop";
import Statistical from "./components/static/Statistical";
const Account = React.lazy(() => import("./view/user/account/Account"));
const Home = React.lazy(() => import("./view/home/Home"));
const ProductDetail = React.lazy(() =>
  import("./view/product/ProductDetail/ProductDetail")
);
const Order = React.lazy(() => import("./view/order/Order"));
const Cart = React.lazy(() => import("./view/cart/Cart"));
const Training = React.lazy(() => import("./view/training/Training"));
const ProductCategory = React.lazy(() =>
  import("./view/category/ProductCategory/ProductCategory")
);
const News = React.lazy(() => import("./view/news/News"));
const NewsDetail = React.lazy(() =>
  import("./view/news/NewsDetail/NewsDetail")
);
const Search = React.lazy(() => import("./view/search/Search"));
const Group = React.lazy(() => import("./view/group/Group"));

const Supplier = React.lazy(() => import("./view/supplier/Supplier"));

const Article = React.lazy(() => import("./view/article"));
const Course = React.lazy(() => import("./view/training/Course"));

function App() {
  useEffect(function () {
    Aos.init({ duration: 1500 });
  }, []);
  const dispatch = useDispatch();

  const { listCourse } = useSelector((state) => state.courseReducer);

  useEffect(() => {
    dispatch(getMyInfo());
    // dispatch(getCourse());
  }, []);

  useEffect(() => {
    nprogress.start();
    nprogress.done();
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollTop>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route element={<ProtectedRoutes />}>
                <Route path="/account/*" element={<Account />} />
                <Route path="/order" element={<Order />} />
                <Route path="/cart" element={<Cart />} />
              </Route>
              <Route
                path="/search/name=:name&desc=:desc&page=:page&size=:size"
                element={<Search />}
              />
              <Route path="/supplier/*" element={<Supplier />} />
              <Route path="/training" element={<Training />} />
              <Route
                path="/training/course"
                element={<Course title={"Khóa học"} data={listCourse} />}
              />
              <Route path="/cooperate" element={<Cooperate />} />
              <Route path="/training/course/:id" element={<Detail />} />
              <Route
                path="/training/knowledge"
                element={<Course title={"Kiến thức nông nghiệp"} />}
              />
              <Route
                path="/training/Q&A"
                element={<Course title={"Hỏi đáp nghề nông"} />}
              />
              <Route
                path="/domesticFairs/:id"
                element={<DomesticFairs text={"Hội chợ trong nước"} />}
              />
              <Route
                path="/foreign-fairs/:id"
                element={<DomesticFairs text={"Hội chợ nước ngoài"} />}
              />
              <Route
                path="/domestic-fairs-detail/:id"
                element={<DomesticFairsDetail />}
              />
              <Route path="/register-stall/:id" element={<RegisterStall />} />
              <Route path="/register-visit/:id" element={<RegisterVisit />} />

              <Route path="/news" element={<News />} />
              <Route path="/news/:cate_news" element={<News />} />
              <Route path="/register-confirm" element={<ConfirmRegister />} />
              <Route path="/verify-newpassword" element={<ForgotPassword />} />
              <Route path="/" element={<Home />} />
              <Route path="/iFrame-shop" element={<IframeShop />} />
              <Route path="/statistical" element={<Statistical />} />
              <Route path="/news/:cate_news/:id" element={<NewsDetail />} />
              <Route path="/product/:id" element={<ProductDetail />} />
              <Route
                path="/onepay/domestic/callback"
                element={<PaymentOnepay />}
              />
              <Route
                path="/category/name=:name&page=:page&size=12&id=:id&:arrange=true"
                element={<ProductCategory />}
              />
              <Route path="/group/*" element={<Group />} />

              <Route path="/article/*" element={<Article />} />
              <Route path="/register-course/:id" element={<RegisterCourse />} />

              <Route path="*" element={<NoMatch />} />
            </Routes>
          </Suspense>
        </ScrollTop>
      </BrowserRouter>
    </div>
  );
}
export default App;
